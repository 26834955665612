import { useForm } from 'react-hook-form';
import Button from '../../../../components/Button';
import Radio from '../../../../components/Radio';
import Text from '../../../../components/Text';
import Title from '../../../../components/Title';
import style from './Form.module.scss';
import { useRef, useState } from 'react';
import { toAnchor } from '../../../../helpers/toAnchor';
import { Link } from 'react-router-dom';

const FormSection = () => {
  const thisForm = useRef(null);
  const [isSent, setSent] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    let formData = new FormData(thisForm.current);
    let xhr = new XMLHttpRequest();

    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4) {
        setSent(true);
        toAnchor('form');

        if (xhr.status === 200) {
          console.log('Mail send');
        }

        setTimeout(() => {
          setSent(false);
          setTimeout(() => {
            thisForm.current.reset();
          }, 0);
        }, 10000);
      }
    };

    xhr.open('POST', 'mail.php', true);
    xhr.send(formData);

    // thisForm.current.reset();
  };

  return (
    <section id='form' className={style.form}>
      <div className='__container'>
        <div className={style.formBody}>
          <Title h1 center className={style.formBody__title}>
            Готовы начать свой проект?
          </Title>

          {isSent && (
            <>
              <div className={style.formBody__success}>
                <div className={style.formBody__successIcon}>
                  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'>
                    <path d='M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z' />
                  </svg>
                </div>
                <Title
                  h4
                  center
                  semiBold
                  className={style.formBody__successText}
                >
                  Спасибо за обращение! <br />В ближайшее время мы с вами
                  свяжемся.
                </Title>
              </div>
            </>
          )}
          {!isSent && (
            <>
              <Title h4 center semiBold className={style.formBody__subtitle}>
                Заполните форму, и мы свяжемся с вами для обсуждения всех
                деталей!
              </Title>
              <form
                ref={thisForm}
                onSubmit={handleSubmit(onSubmit)}
                className={style.formBody__content}
              >
                <label className={`${style.input} ${errors.name && style.err}`}>
                  <input
                    {...register('name', { required: true })}
                    type='text'
                    placeholder='Имя*'
                  />
                </label>
                <label
                  className={`${style.input} ${errors.email && style.err}`}
                >
                  <input
                    {...register('email', {
                      required: true,
                      pattern: {
                        value: /\S+@\S+\.\S+/,
                        message: '',
                      },
                    })}
                    type='text'
                    placeholder='E-mail*'
                  />
                </label>
                <label className={style.input}>
                  <input
                    {...register('tel', { required: false })}
                    type='text'
                    placeholder='Телефон'
                  />
                </label>
                <label className={style.input}>
                  <input
                    {...register('telegram', { required: false })}
                    type='text'
                    placeholder='@Telegram'
                  />
                </label>
                <label className={style.input}>
                  <textarea
                    {...register('about', { required: false })}
                    placeholder='Расскажите о своей идее или задумке'
                  ></textarea>
                </label>
                <div className={style.row}>
                  <Title h4 semiBold className={style.row__title}>
                    Тип
                  </Title>
                  <div className={style.row__content}>
                    {/* <Radio
                      className={style.radio}
                      name={'websiteType'}
                      value={'tilda-landing'}
                      title={'Лендинг на Tilda'}
                      isError={errors.websiteType}
                      register={register}
                      required
                      // {...register('websiteType', { required: true })}
                    /> */}
                    <Radio
                      className={style.radio}
                      name={'websiteType'}
                      isError={errors.websiteType}
                      value={'premium-landing'}
                      // title={'Лендинг'}
                      title={'Лендинг'}
                      register={register}
                      required
                      // {...register('websiteType', { required: true })}
                    />
                    <Radio
                      className={style.radio}
                      name={'websiteType'}
                      isError={errors.websiteType}
                      value={'corporate-site'}
                      title={'Корпоративный сайт'}
                      register={register}
                      required
                      // {...register('websiteType', { required: true })}
                    />
                    <Radio
                      className={style.radio}
                      name={'websiteType'}
                      isError={errors.websiteType}
                      value={'visit-site'}
                      title={'Сайт-визитка'}
                      register={register}
                      required
                      // {...register('websiteType', { required: true })}
                    />
                    <Radio
                      className={style.radio}
                      name={'websiteType'}
                      isError={errors.websiteType}
                      value={'other-site'}
                      title={'Другое'}
                      register={register}
                      required
                      // {...register('websiteType', { required: true })}
                    />
                  </div>
                </div>
                <div className={style.row}>
                  <Title h4 semiBold className={`${style.row__title}`}>
                    Бюджет
                  </Title>
                  <div className={style.row__content}>
                    <Radio
                      className={style.radio}
                      name={'budget'}
                      title={'До 70 тыс. руб'}
                      value={'70k'}
                      register={register}
                      required
                      isError={errors.budget}
                    />
                    <Radio
                      className={style.radio}
                      name={'budget'}
                      title={'От 70 до 150 тыс. руб.'}
                      value={'70k-150k'}
                      register={register}
                      required
                      isError={errors.budget}
                    />
                    <Radio
                      className={style.radio}
                      name={'budget'}
                      title={'От 150+ тыс. руб.'}
                      value={'150k+'}
                      register={register}
                      required
                      isError={errors.budget}
                    />
                  </div>
                </div>
                <label
                  className={`${style.agree} ${errors.agree && style.err}`}
                >
                  <input
                    type='checkbox'
                    {...register('agree', { required: true })}
                  />
                  <div className={style.agreeFake}>
                    <div className={style.agreeFake__check}>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        viewBox='0 0 448 512'
                      >
                        <path d='M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z' />
                      </svg>
                    </div>
                    <Text fz14 className={style.agreeFake__text}>
                      Я соглашаюсь на{' '}
                      <Link
                        to={'policy'}
                        onClick={() => {
                          window.scrollTo({
                            top: 0,
                            behavior: 'smooth',
                          });
                        }}
                      >
                        условия обработки персональных данных
                      </Link>
                    </Text>
                  </div>
                </label>
                <Button
                  title={'Получить консультацию'}
                  className={style.formBody__contentBtn}
                />
              </form>
            </>
          )}
        </div>
      </div>
    </section>
  );
};

export default FormSection;
