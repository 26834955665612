import Text from '../../../../../../components/Text';
import Title from '../../../../../../components/Title';
import style from './TextCard.module.scss';

const TextCard = ({ num, title, text, className }) => {
  return (
    <div className={`${style.card} ${className}`}>
      <div className={style.card__top}>
        <Title h3 className={style.card__num}>
          {num}
        </Title>
        <Title h3 black className={style.card__title}>
          {title}
        </Title>
      </div>
      <Text black className={style.card__text}>
        {text}
      </Text>
    </div>
  );
};

export default TextCard;
