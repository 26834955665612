import { useGSAP } from '@gsap/react';
import Title from '../../../../components/Title';
import TextCard from './components/TextCard';
import style from './Steps.module.scss';
import { useRef } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { imageSequence } from '../../../../helpers/gsapSequence';
import { useMediaQuery } from 'usehooks-ts';

gsap.registerPlugin(useGSAP);
gsap.registerPlugin(ScrollTrigger);

const StepsSection = () => {
  const sectionRef = useRef(null);
  const bodyRef = useRef(null);
  const canvasRef = useRef(null);
  const isTablet = useMediaQuery('(max-width:991px)');

  // useGSAP(
  //   () => {
  //     const animEnd = `${sectionRef.current.scrollHeight * 3}px`;
  //     const itemList = sectionRef.current.querySelectorAll(
  //       `.${style.stepsBody__contentCard}`
  //     );

  //     const tl = gsap.timeline({
  //       scrollTrigger: {
  //         trigger: sectionRef.current,
  //         pin: false,
  //         markers: false,
  //         anticipatePin: 1, // добавьте эту строку

  //         scrub: 2,
  //         start: 'top top',
  //         end: animEnd,

  //         ease: 'none',
  //       },
  //     });
  //     const tlBody = gsap.timeline({
  //       scrollTrigger: {
  //         trigger: sectionRef.current,
  //         pin: false,
  //         markers: false,
  //         anticipatePin: 1, // добавьте эту строку

  //         scrub: 2,
  //         start: '-=500',
  //         end: 'top top',

  //         ease: 'none',
  //       },
  //     });

  //     let frameCount = 99,
  //       urls = new Array(frameCount)
  //         .fill()
  //         .map(
  //           (o, i) =>
  //             `./assets/steps-sequence/${(i + 1)
  //               .toString()
  //               .padStart(3, '0')}.jpg`
  //         );

  //     imageSequence({
  //       urls,
  //       canvas: canvasRef.current,
  //       scrollTrigger: {
  //         scrub: true,
  //         trigger: sectionRef.current,
  //         pin: true,
  //         markers: false,
  //         // start: () => `-=${window.innerHeight * 0.25}`,
  //         // end: () => `+=${window.innerHeight * 1}`,
  //         // start: () => `top`,
  //         // end: () => `bottom`,
  //         start: 'top top',
  //         end: animEnd,
  //         // ease: 'none',
  //       },
  //     });

  //     itemList.forEach((element, index) => {
  //       if (index === 0) {
  //         // tl.from(element, {
  //         //   y: 0,
  //         // });
  //         tl.to(
  //           element,
  //           {
  //             x: isTablet ? -400 : 0,
  //             y: !isTablet ? -400 : 0,
  //             opacity: 0,
  //             scale: 0.5,
  //             duration: 1 / (itemList.length * 2 - index),
  //           },
  //           0.02
  //         );
  //       } else if (index === itemList.length - 1) {
  //         tl.from(
  //           element,
  //           {
  //             // x: isTablet ? -400 : 0,
  //             y: 400,
  //             opacity: 0,
  //             scale: 0.5,
  //             duration: 1 / (itemList.length * 2 - index),
  //           },
  //           (1 / (itemList.length * 2 - index)) * (index - 1)
  //           // 0
  //           // 0.2
  //         );
  //       } else {
  //         tl.from(
  //           element,
  //           {
  //             // x: isTablet ? 400 : 0,
  //             y: 400,
  //             opacity: 0,
  //             scale: 0.5,
  //             duration: 1 / (itemList.length * 2 - index),
  //           },
  //           (1 / (itemList.length * 2 - index)) * (index - 1)
  //           // 0
  //           // 0.2
  //         );
  //         tl.to(
  //           element,
  //           {
  //             x: isTablet ? -400 : 0,
  //             y: !isTablet ? -400 : 0,
  //             opacity: 0,
  //             scale: 0.5,
  //             duration: 1 / (itemList.length * 2 - index) + 0.1,
  //           },
  //           (1 / (itemList.length * 2 - index)) * (index - 0)
  //           // 0.2 * 1 = 0.2
  //           // 0.2 * 2 = 0.4

  //           // (1 / (itemList.length * 2)) * index
  //           // 0.4
  //         );
  //       }
  //     });

  //     tlBody.from(
  //       bodyRef.current,
  //       {
  //         y: window.innerHeight / 2,
  //         scale: 0.5,
  //       },
  //       0
  //     );
  //   },
  //   {
  //     scope: sectionRef,
  //   }
  // );

  return (
    <section id='steps' ref={sectionRef} className={style.steps}>
      <div className='__container'>
        <Title h1 center>
          Наш процесс работы
        </Title>
        <div ref={bodyRef} className={style.stepsBody}>
          <div className={style.stepsBody__content}>
            <TextCard
              num={'01'}
              title={'Брифинг и анализ'}
              text={
                <>
                  Обсуждаем ваш проект, изучаем вашу аудиторию и цели. Формируем
                  техническое задание.
                </>
              }
              className={style.stepsBody__contentCard}
            />
            <TextCard
              num={'02'}
              title={'Прототипирование'}
              text={
                <>
                  Разрабатываем прототип сайта, чтобы определить структуру и
                  ключевые элементы интерфейса.
                </>
              }
              className={style.stepsBody__contentCard}
            />
            <TextCard
              num={'03'}
              title={'Дизайн'}
              text={
                <>
                  Создаём уникальный визуальный стиль, который отражает ваш
                  бренд и нравится вашей аудитории.
                </>
              }
              className={style.stepsBody__contentCard}
            />
            <TextCard
              num={'04'}
              title={'Верстка и разработка'}
              text={
                <>
                  Реализуем дизайн в адаптивной и быстрой верстке, подключаем
                  нужный функционал.
                </>
              }
              className={style.stepsBody__contentCard}
            />
            <TextCard
              num={'05'}
              title={'Тестирование'}
              text={
                <>
                  Проверяем сайт на всех устройствах, устраняем ошибки и
                  оптимизируем под SEO.
                </>
              }
              className={style.stepsBody__contentCard}
            />
            <TextCard
              num={'06'}
              title={'Запуск'}
              text={
                <>
                  Размещаем сайт на хостинге, подключаем домен и готовим проект
                  к публичному запуску.
                </>
              }
              className={style.stepsBody__contentCard}
            />
            <TextCard
              num={'07'}
              title={'Техническая поддержка'}
              text={
                <>
                  Обеспечиваем стабильную работу сайта, вносим обновления и
                  исправляем ошибки.
                </>
              }
              className={style.stepsBody__contentCard}
            />
          </div>
          {/* <div className={style.stepsBody__anim}>
            <canvas width={1920} height={1080} ref={canvasRef}></canvas>
          </div> */}
        </div>
      </div>
    </section>
  );
};

export default StepsSection;
