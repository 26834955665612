import AdvantagesSection from './sections/Advantages';
import CasesSection from './sections/Cases';
import CtaSection from './sections/Cta';
import FaqSection from './sections/Faq';
import FormSection from './sections/Form';
import MainSection from './sections/Main';
import PortfolioSection from './sections/Portfolio';
import ReviewsSection from './sections/Reviews';
import ServicesSection from './sections/Services';
import StepsSection from './sections/Steps';

const IndexPage = () => {
  return (
    <>
      <MainSection />
      <CasesSection />
      <ServicesSection />
      {/* <AdvantagesSection /> */}
      <StepsSection />
      <PortfolioSection />
      <ReviewsSection />
      <CtaSection />
      <FormSection />
      <FaqSection />
    </>
  );
};

export default IndexPage;
