import { useLayoutEffect, useRef } from 'react';
import style from './Logo.module.scss';
import logoNew from './assets/logo-new.svg';
import logo from './assets/logo.png';
import logo2x from './assets/logo@2x.png';
import gsap from 'gsap';

const Logo = ({ className, black, onClick }) => {
  const textWrapperRef = useRef(null);
  const comp = useRef();

  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      const tl = gsap.timeline({ repeat: -1, repeatDelay: 0 });

      tl.to(textWrapperRef.current, { y: '-50%' }, 5);
      tl.to(textWrapperRef.current, { y: '0%' }, 10);
    }, comp);

    return () => ctx.revert(); // cleanup
  }, []);

  return (
    <div
      ref={comp}
      onClick={onClick}
      className={`${style.logo} ${className} ${black && style.black}`}
    >
      <img src={logoNew} alt='' />
      {/* <div className={style.logo__img}>
        <img src={logo} srcSet={`${logo} 1x, ${logo2x} 2x`} alt='' />
      </div>
      <div className={style.logoText}>
        <div className={style.logoText__hidden}>
          <div className={style.logoText__title}>NotEqual</div>
          <div className={style.logoText__label}>Web Agency</div>
        </div>
        <div ref={textWrapperRef} className={style.logoText__wrapper}>
          <div className={style.logoText__big}>
            <div className={style.logoText__title}>NotEqual</div>
            <div className={style.logoText__label}>Web Agency</div>
          </div>
          <div className={style.logoText__small}>NEWA</div>
        </div>
      </div> */}
    </div>
  );
};

export default Logo;
