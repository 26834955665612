import Button from '../../../../components/Button';
import Text from '../../../../components/Text';
import Title from '../../../../components/Title';
import { toAnchor } from '../../../../helpers/toAnchor';
import style from './Main.module.scss';

const MainSection = () => {
  return (
    <section className={style.main}>
      <div className='__container'>
        <div className={style.mainBody}>
          <Text md center fz16>
            NEWA | Веб-разработка под ключ
          </Text>
          <Title h1 center className={style.mainBody__title}>
            {/* Создаём сайты, <br />
            которые <span>продают</span> */}
            Современные сайты, которые <span>работают</span> на ваш бизнес
          </Title>
          <Title h4 md center className={style.mainBody__subtitle}>
            {/* Максимальная конверсия, минимальные сроки */}
            Мы создаём, тестируем и поддерживаем ваш проект на всех этапах
            {/* Дизайн, верстка, разработка под ключ с гарантией результата */}
          </Title>
          <Button
            // title={'Получить бесплатную консультацию'}
            // title={'Обсудить ваш проект'}
            title={'Бесплатная консультация'}
            className={style.mainBody__btn}
            lg
            onClick={() => toAnchor('form')}
          />
        </div>
      </div>
    </section>
  );
};

export default MainSection;
