import Title from '../../../../components/Title';
import style from './Reviews.module.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';

import 'swiper/css';
// import 'swiper/css/navigation';
// import 'swiper/css/pagination';
import Text from '../../../../components/Text';
import { Autoplay } from 'swiper/modules';
import { useMediaQuery } from 'usehooks-ts';

const reviews = [
  {
    id: 0,
    name: 'Борис Гудков',
    role: 'Marketing Director',
    content:
      'Сотрудничаю с Кириллом уже не первый раз. Все сделано качественно и в срок. Рекомендую!',
    avatar: null,
  },
  {
    id: 1,
    name: 'Григорий Дядиченко',
    role: '',
    content: 'Всё супер. Быстро и качественно.',
    avatar: null,
  },
  {
    id: 2,
    name: '[eric-london]',
    role: '',
    content:
      'Отличный исполнитель,выполнил работу быстро, качественно и быстрее заданного срока!',
  },
  {
    id: 3,
    name: '[bus_versal]',
    role: '',
    content:
      'Отличная работа! Все сделано быстро и качественно, надеюсь на дальнейшее сотрудничество!',
  },
  {
    id: 4,
    name: 'Борис Гудков',
    role: '',
    content:
      'Все было сделано вовремя. Все пожелания и правки были учтены.Сотрудничеством доволен. Рекомендую!',
  },
  {
    id: 5,
    name: '[john903]',
    role: '',
    content:
      'Отличная работа. Всё выполнено как надо. На все замечания мастер отреагировал спокойно и быстро всё исправил. К слову сказать – замечаний было минимум.',
  },
  {
    id: 6,
    name: 'Николай Семенов',
    role: '',
    content:
      'Ответственный, знающий своё дело специалист. Быстро выполнил мой заказ, и без проблем сделал доработку. Рекомендую',
  },
  {
    id: 7,
    name: 'Анастасия',
    role: '',
    content:
      'Все было сделано вовремя. Все пожелания и правки были учтены. Сотрудничеством доволен. Рекоменду!',
  },
  {
    id: 8,
    name: 'Иван',
    role: '',
    content:
      'Сделали все не только, как я хотел, но и предложили пару идей, которые сделали сайт еще лучше. Исполнитель всегда был на связи. Спасибо за работу, рекомендую!',
  },
  {
    id: 9,
    name: 'Камила',
    role: '',
    content:
      'Очень качественная и быстрая работа 👍🏻 С первых сообщений чувствуется профессионализм исполнителя) При этом приятные цены) Спасибо большое за создание сайта)',
  },
];

const ReviewsSection = () => {
  const isTablet = useMediaQuery('(max-width:991px)');
  const isMobile = useMediaQuery('(max-width:767px)');

  return (
    <section id='reviews' className={style.reviews}>
      <div className='__container'>
        <Title h1 center>
          Отзывы
        </Title>
        <div className={style.reviewsBody}>
          <button
            id='reviews-slider-prev'
            className={`${style.reviewsBody__nav} ${style.prev}`}
          >
            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'>
              <path d='M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z' />
            </svg>
          </button>
          <button
            id='reviews-slider-next'
            className={`${style.reviewsBody__nav} ${style.next}`}
          >
            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'>
              <path d='M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z' />
            </svg>
          </button>
          <Swiper
            modules={[Autoplay, Navigation]}
            spaceBetween={isMobile ? 8 : isTablet ? 8 : 32}
            slidesPerView={isMobile ? 1 : isTablet ? 2 : 3}
            className={style.reviewsSlider}
            loop
            autoplay={{
              delay: 5000,
            }}
            navigation={{
              prevEl: '#reviews-slider-prev',
              nextEl: '#reviews-slider-next',
            }}
            // navigation
          >
            {reviews.reverse().map((review) => (
              <SwiperSlide
                key={review.id}
                className={style.reviewsSlider__slide}
              >
                <div className={style.card}>
                  <div className={style.cardTop}>
                    <div className={style.cardTop__img}>
                      {review.avatar && <img src={review.avatar} alt='' />}

                      <Title h1 center>
                        {review.name.slice(0, 1) === '['
                          ? review.name.slice(1, 2)
                          : review.name.slice(0, 1)}
                      </Title>
                    </div>
                    <div className={style.cardTop__text}>
                      <Title black h4 className={style.cardTop__textName}>
                        {review.name}
                      </Title>
                      <Title
                        black
                        h5
                        semiBold
                        className={style.cardTop__textPos}
                      >
                        {review.role}
                      </Title>
                    </div>
                  </div>
                  <div className={style.card__rating}>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='24'
                      height='24'
                      viewBox='0 0 24 24'
                      fill='none'
                      stroke='currentColor'
                      stroke-width='2'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      class='lucide lucide-star w-5 h-5 fill-yellow-400 text-yellow-400'
                    >
                      <polygon points='12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2'></polygon>
                    </svg>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='24'
                      height='24'
                      viewBox='0 0 24 24'
                      fill='none'
                      stroke='currentColor'
                      stroke-width='2'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      class='lucide lucide-star w-5 h-5 fill-yellow-400 text-yellow-400'
                    >
                      <polygon points='12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2'></polygon>
                    </svg>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='24'
                      height='24'
                      viewBox='0 0 24 24'
                      fill='none'
                      stroke='currentColor'
                      stroke-width='2'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      class='lucide lucide-star w-5 h-5 fill-yellow-400 text-yellow-400'
                    >
                      <polygon points='12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2'></polygon>
                    </svg>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='24'
                      height='24'
                      viewBox='0 0 24 24'
                      fill='none'
                      stroke='currentColor'
                      stroke-width='2'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      class='lucide lucide-star w-5 h-5 fill-yellow-400 text-yellow-400'
                    >
                      <polygon points='12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2'></polygon>
                    </svg>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='24'
                      height='24'
                      viewBox='0 0 24 24'
                      fill='none'
                      stroke='currentColor'
                      stroke-width='2'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      class='lucide lucide-star w-5 h-5 fill-yellow-400 text-yellow-400'
                    >
                      <polygon points='12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2'></polygon>
                    </svg>
                  </div>
                  <Text fz14 black className={style.card__text}>
                    {review.content}
                  </Text>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </section>
  );
};

export default ReviewsSection;
