// stats icons
import statsIcon01 from './assets/img/stats/01.svg';
import statsIcon02 from './assets/img/stats/02.svg';
import statsIcon03 from './assets/img/stats/03.svg';
// social icons
import telegramIcon from './assets/img/social/tg';
import instagramIcon from './assets/img/social/inst';
import vkIcon from './assets/img/social/vk';
import flIcon from './assets/img/social/fl';
import behanceIcon from './assets/img/social/be';
import avitoIcon from './assets/img/social/avito';
// why section icons
import whyIcon1 from './assets/img/why/icon1.svg';
import whyIcon2 from './assets/img/why/icon2.svg';
import whyIcon3 from './assets/img/why/icon3.svg';
// form img
import formImg from './assets/img/form/img.jpg';

export const content = {
  socialLinks: [
    {
      icon: telegramIcon,
      href: 'https://t.me/newa_agency',
    },
    {
      icon: instagramIcon,
      href: 'https://www.instagram.com/newa.agency/',
    },
    {
      icon: vkIcon,
      href: 'https://vk.com/newa_agency',
    },
    // {
    //   icon: flIcon,
    //   href: '',
    // },
    // {
    //   icon: behanceIcon,
    //   href: '',
    // },
    {
      icon: avitoIcon,
      href: 'https://www.avito.ru/user/03ee04d2f3492a2f0257a529f731faf0/profile/all?id=3556300725&src=item&page_from=from_item_card&iid=3556300725&sellerId=03ee04d2f3492a2f0257a529f731faf0',
    },
  ],
  headerAnchors: [
    // { name: 'Главная', to: '/' },
    // { name: 'О нас', to: 'about' },
    // { name: 'Услуги', to: 'services' },
    // { name: 'Почему мы', to: 'why' },
    // { name: 'Портфолио', to: 'portfolio' },
    // { name: 'Цены', to: 'services' },
    { name: 'Кейсы', to: 'cases' },
    { name: 'Услуги', to: 'services' },
    // { name: 'Этапы', to: 'steps' },
    { name: 'Наши проекты', to: 'projects' },
    { name: 'Отзывы', to: 'reviews' },
    { name: 'FAQ', to: 'faq' },
    { name: 'Контакты', to: 'footer' },
  ],
  homePage: {
    mainSection: {
      label: 'NotEqual Web Agency - NEWA',
      title: (
        <>
          {/* Мы создаем легкие решения <br /> сложных задач и проблем */}
          Ваш успех в сети <br /> начинается с нашего дизайна
        </>
      ),
    },
    aboutSection: {
      title: 'Высокие стандарты разработки',
      description: (
        <>
          Наши высокие стандарты — это не просто слова, это наша философия,
          которая пронизывает каждый проект, созданный в нашей веб-студии.{' '}
          <br /> Мы стремимся к безупречности и уделяем внимание каждой детали.
        </>
      ),
      stats: [
        {
          icon: statsIcon01,
          value: '80',
          label: 'Успешных проектов',
        },
        {
          icon: statsIcon02,
          value: '30',
          label: 'Довольных клиентов ',
        },
        {
          icon: statsIcon03,
          value: '4',
          label: 'Лет опыта',
        },
      ],
    },
    servicesSection: {
      label: 'Цены',
      title: 'Наши услуги',
      list: [
        {
          type: 'landing',
          name: 'Лендинг',
          price: 12000,
          description:
            'Лендинг с конверсией от 4% созданный для сбора контактов и представления вашего уникального продукта. Она раскрывает преимущества продукта, важность и устанавливает доверие между компанией, продуктом и посетителем.',
        },
        {
          type: 'corporate',
          name: 'Корпоративный сайт',
          price: 25000,
          description:
            'Корпоративные сайты - это большие многостраничные ресурсы, предназначенные для создания имиджа компании в интернете. Здесь размещается информация о услугах, преимуществах и продукции компании.',
        },
        {
          type: 'shop',
          name: 'Интернет-магазин',
          price: 49000,
          description:
            'Онлайн-магазин предоставляет карточки товаров, управление ценами и скидками, онлайн-оплату с множеством платежных систем, интеграцию с CRM, онлайн-консультантом, и каталог с фильтрацией, поиском и импортом/экспортом продукции.',
        },
      ],
    },
    whySection: {
      label: 'Преимущества нашей компании',
      title: 'Почему выбирают нас',
      list: [
        {
          icon: whyIcon1,
          title: 'Комплексный подход',
          description:
            'Мы предоставляем широкий спектр услуг, от создания сайтов-визиток до масштабных интернет-магазинов.',
        },
        {
          icon: whyIcon2,
          title: 'Современный дизайн',
          description:
            'Наши дизайны актуальны и привлекательны, что помогает вашему бизнесу выделяться.',
        },
        {
          icon: whyIcon3,
          title: 'Поддержка и обслуживание',
          description:
            'Мы не оставляем вас после завершения проекта. Предоставляем надежную поддержку и обновления.',
        },
      ],
    },
    portfolioSection: {
      title: 'Портфолио',
      // list: [
      //   {
      //     img: testImg,
      //     name: 'Название проекта 1',
      //   },
      //   {
      //     img: testImg,
      //     name: 'Название проекта 2',
      //   },
      //   {
      //     img: testImg,
      //     name: 'Название проекта 3',
      //   },
      //   {
      //     img: testImg,
      //     name: 'Название проекта 4',
      //   },
      //   {
      //     img: testImg,
      //     name: 'Название проекта 5',
      //   },
      //   {
      //     img: testImg,
      //     name: 'Название проекта 6',
      //   },
      // ],
    },
    formSection: {
      img: formImg,
      title: 'Хотите заказать проект?',
      label: 'Оставьте заявку, и мы с вами свяжемся',
      thanks: {
        title: 'Спасибо за обращение!',
        text: 'В ближайщее время мы с вами свяжемся',
      },
    },
  },
  footer: {
    text: (
      <>
        Мы - творческая веб-студия, которая воплощает вашу уникальность в
        интернете. <br /> Наши дизайнеры и разработчики создают веб-сайты,
        которые вдохновляют. Мы стремимся к совершенству, чтобы помочь вашему
        бизнесу процветать.
      </>
    ),
    ip: (
      <>
        ИП Махнев Кирилл Константинович <br />
        ОГРН/ОГРНИП 324180000026253
      </>
    ),
    cr: '© 2020-2025 NotEqual Web Agency',
    // cr: (
    //   <>
    //     © 2020-2025 <span>N</span>ot <span>E</span>qual <span>W</span>eb{' '}
    //     <span>A</span>gency
    //   </>
    // ),
    // cr: '© 2020-2025 NEWA Agency',
    privacy: {
      link: '/privacy',
      text: 'Политика конфиденциальности',
    },
    mail: 'manager@newa.agency',
  },
};
